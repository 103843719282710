import React, { Fragment } from "react";
import NavBar from "../components/NavBar.jsx";
import TitleBox, { TitleBoxHeights } from "../components/TitleBox.jsx";
import {
  GridItemCardList,
  CardListContainer,
} from "../components/MainPageGridItem.jsx";
import { ResponsiveDateCard } from "../components/ResponsiveItemCard.jsx";
import { graphql } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer.jsx";
import { Helmet } from "react-helmet";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

export const query = graphql`
  query($draft: Boolean!, $today: KEYSTONE_DateTime!) {
    keystone {
      allEvents(
        sortBy: date_DESC
        where: { draft: $draft, pubDate_lte: $today }
      ) {
        coverImage {
          publicUrl
        }
        date
        title
        slug
      }
    }
  }
`;

function createCardList(events, title, colorIndex) {
  return (
    <GridItemCardList colorNumber={colorIndex} title={title}>
      {events.map((x, i) => (
        <CardListContainer queryText={x.title} key={i} navigate={x.slug}>
          <ResponsiveDateCard
            title={x.title}
            image={x.coverImage ? x.coverImage.publicUrl : undefined}
            date={new Date(x.date)}
            time={true}
          />
        </CardListContainer>
      ))}
    </GridItemCardList>
  );
}

const TreatmentsPageTemplate = ({ data }) => {
  const nextEvents = data.keystone.allEvents.filter(
    (e) => new Date(e.date) >= new Date()
  );
  const pastEvents = data.keystone.allEvents.filter(
    (e) => new Date(e.date) < new Date()
  );
  var colorIndex = 0;

  return (
    <Fragment>
      <Helmet>
        <title>Notizie - Poliambulatorio Giano</title>
        <meta
          name="description"
          content="Gli eventi organizzati dal nostro poliambulatorio"
        />
      </Helmet>
      <NavBar />
      <TitleBox heights={TitleBoxHeights.contentPageHeights}>
        <Text variant="pagesTitle">Eventi</Text>
      </TitleBox>
      <Grid container>
        {nextEvents &&
          nextEvents.length > 0 &&
          createCardList(nextEvents, "Prossimi Eventi", colorIndex++)}
        {pastEvents &&
          pastEvents.length > 0 &&
          createCardList(pastEvents, "Eventi Passati", colorIndex++)}
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default TreatmentsPageTemplate;
